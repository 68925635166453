import React, { useState, useEffect, useRef } from 'react'
import { Menu } from './Menu';
import { HeaderSection } from './HeaderSection';
import { ProjectPage } from './ProjectPage';
import "./Menu.css"
import { AboutMe } from './AboutMe';
import SmoothScroll from "../SmoothScroll";
import { ScrollProvider } from '../../Context';
import { ContactPage } from './ContactPage';
import { motion } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'

export const HomePage = () => {

    const [realMenu, setRealMenu] = useState(false);
    const inputEl = useRef(null);
    const size = useWindowSize();
    const [style,setStyle] = useState({opacity:0});

    useEffect(() => {
        if(window.location.search.indexOf("scrollToProject") !== -1) {
            window.scroll(0,(size.height * 2)+100)
        }
        setTimeout(() => {
            setStyle({})
        },1000)
    }, [])

    let changeRealMenu = () => {
        setRealMenu(true);
    }

    return (
        <div id="fullpage" ref={inputEl}>
            <motion.div className="transition" initial={{ left:-200 }} animate={{ left: size.width + 200}} exit={{ left: -200 }} transition={{ duration: 1 }}/>
            <ScrollProvider>
                <SmoothScroll>
                    <HeaderSection onFinished={changeRealMenu} />
                    <AboutMe />
                    <ProjectPage />
                    <ContactPage />
                </SmoothScroll>
            </ScrollProvider>
            {realMenu ? <Menu /> :
                <div className="menu" style={style}>
                    <div className="menu_home">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                </div>

            }

        </div>
    )
}
