import React from "react";
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HomePage } from "./components/Home/HomePage";
import { ProjectDescPage } from "./components/ProjectDesc/ProjectDescPage";
import "./App.css";
import { AnimatePresence } from "framer-motion";

export default function App() {

  const location = useLocation();
  

  return (
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route path="/projects/:id" component={ProjectDescPage} />
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </AnimatePresence>
  );
}
