import React, { useEffect } from 'react'
import "./ProjectPage.css"
import { SlideProject } from './SlideProject';
import { projects } from '../../Projects';




export const ProjectPage = () => {




    useEffect(() => {
        console.clear();
        console.log("What are you doing here ???");
        console.log("Are you trying to break my site ?")
        console.log("")
        console.log("please don't, I can do it quite well by myself...")
        
    }, [])


    return (
                <section className="project section" id="project">
                    <div className="project_carousel">
                        <div className="project_slides">
                            {projects.map((e,i) => <SlideProject indice={i} key={e.oneWord} elem={e} />)}
                        </div>
                    </div>
                </section>

    )
}
