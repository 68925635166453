import React from 'react'
import "./ContactPage.css"
import linkedin from "../../assets/Logos/linkedin.png"
import github from "../../assets/Logos/github.png"

export const ContactPage = () => {

  return (
    <section className="contact">
        <p className="contact_mainButton" onClick={() => window.location.href = "mailto:eliottgdl@gmail.com?subject=I love your website&body= What an amazing work"}> Contact me 💌 </p>
        <div className="contact_socials">
          <a href="https://github.com/eliottgdl" rel="noreferrer" target="_blank">
            <img src={github} alt="github" />
          </a>
          <a href="https://www.linkedin.com/in/eliott-gandiolle-013401180/" rel="noreferrer" target="_blank">
            <img src={linkedin} alt="linkeding" />
          </a>
        </div>
    </section>
  )
}
