import * as React from 'react'

const ScrollContext = React.createContext({scroll:0})

function scrollReducer(state, action) {
  switch (action.type) {
    case 'change': {
      return {scroll: action.value}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function ScrollProvider({children}) {
  const [state, dispatch] = React.useReducer(scrollReducer, {scroll:0})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
}

function useScroll() {
    const context = React.useContext(ScrollContext)
    if (context === undefined) {
      throw new Error('useScroll must be used within a ScrollProvider')
    }
    return context
  }
  export {ScrollProvider, useScroll}