import React, { useEffect, useState, useCallback } from 'react'
import { projects } from '../../ProjectsHQimg'
import "./ProjectDescPage.css"
import { Link } from "react-router-dom"
import { motion } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import arrow from "../../assets/arrow.png"
import php from "../../assets/Logos/php.jpg"
import riot from "../../assets/Logos/riot.png"
import react from "../../assets/Logos/react.png"
import node from "../../assets/Logos/nodeR.png"
import firebase from "../../assets/Logos/firebase.png"
import materialui from "../../assets/Logos/materialui.avif"
import reactrouter from "../../assets/Logos/reactrouter.png"
import scraping from "../../assets/Logos/scraping.png"
import next from "../../assets/Logos/next.png"
import gsap from "../../assets/Logos/gsap.png"
import js from "../../assets/Logos/jsR.png"
import reactnative from "../../assets/Logos/reactnative.png"
import tailwind from "../../assets/Logos/tailwind.png"
import spotify from "../../assets/Logos/spotify.png"
import aws from "../../assets/Logos/aws.webp"
import redux from "../../assets/Logos/redux.png"
import ImageViewer from "react-simple-image-viewer";

const logos = {
    "React": react,
    "Firebase": firebase,
    "React Native": reactnative,
    "AWS": aws,
    "React Redux": redux,
    "GSAP": gsap,
    "Spotify Api": spotify,
    "Riot Games Api": riot,
    "Material Ui": materialui,
    "Nodejs": node,
    "React Router": reactrouter,
    "Web scraping": scraping,
    "NextJs": next,
    "PHP": php,
    "JS": js,
    "TailWind CSS": tailwind

}

export const ProjectDescPage = ({ match: { params: { id } } }) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const size = useWindowSize();
    const projectIndex = projects.findIndex(e => e.oneWord.toLowerCase() === id.replace("+", " "));
    useEffect(() => {
        document.body.style.height = "100vh";
        window.scroll(0, 0)
    }, [])

    const project = projects[projectIndex];
    const next = projects[projectIndex < projects.length - 1 ? projectIndex + 1 : 0].url;

    const { link, img, tools, descr, oneWord, titre, date } = project;

    return (
        <div className="projet">
            <Link to={"/?scrollToProject=true"} >
                <img className="projet_close" src="https://static.thenounproject.com/png/1202535-200.png" alt="close" />
            </Link>
            <motion.div className="transition" initial={{ left: -200 }} animate={{ left: - (size.width + 200) }} exit={{ left: 0 }} transition={{ duration: 1 }} />

            <div className="projet_left">
                <h1 className="projet_titre"> {oneWord} </h1>
                <h2 className="projet_st"> {titre} </h2>
                {tools.map(e => <div key={e} className="projet_tool"> <div className="projet_tool_logo"> <img src={logos[e]} alt={e} /> </div> <p> {e} </p> </div>)}
                <p className="projet_date"> {date} {link !== null ? "/" : ""} {link !== null ? <a className="project_link" href={link} target="_blank"> Link </a> : ""} </p>
            </div>

            <div className="projet_right">
                <h2 className="projet_description">
                    {descr}
                </h2>
                {img.map((e,i)=> <img src={e} alt={e} key={e} onClick={() => openImageViewer(i)} />)}
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={img}
                    currentIndex={currentImage}
                    disableScroll={true}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            <Link to={next}>
                <motion.div className="projet_next" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ rotate: 860 }} transition={{ duration: 1 }}>
                    <img className="projet_nextImg" src={arrow} alt="next" />
                </motion.div>
            </Link>
        </div>
    )
}
