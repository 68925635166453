import img12 from "./assets/LowQuality/spotifyAn2.jpg"
import img42 from "./assets/LowQuality/leaguePro2.jpg"
import img2 from "./assets/LowQuality/spotifo.JPG"
import img3 from "./assets/LowQuality/classified.JPG"
import img5 from "./assets/LowQuality/top10.jpg"

import imgA from "./assets/LowQuality/mwoa.JPG"
import imgC from "./assets/LowQuality/gamblingIIE.JPG"

export const projects = [{
    titre: "Website for the student association of my school",
    url: "/projects/classified",
    img: img3,
    link: "https://classified.iiens.net",
    bg: "#B7A479",
    oneWord: "CLASSIFIED",
    date: "Late 2020",
    tools: ["React","React Router"],
    descr:"We had made a team to campaign for the student office. I made this website for the occasion with some games and easter eggs. I also had to make an OAuth connection in php but it is not on the online version of the site. Unfortunately we didn't win the elections, we finished 2nd... "
}, {
    titre: "Play and discover some top 10",
    url: "/projects/top+10",
    img: img5,
    bg: "#FBC407",
    link: "https://top10.eliottgandiolle.fr",
    oneWord: "TOP 10",
    date: "Mid 2020",
    tools: ["React","GSAP","Firebase","Web scraping"],
    descr: "It's a game/website that allowed to get real time rankings (the 10 youtube videos with the most views, same with spotify music...). You could then either explore them or try to guess them, which is quite difficult! (The library that allowed me to scrape doesn't work anymore so some rankings are not up to date)",
},{
    titre: "Gambling website for school",
    url: "/projects/gambliie",
    img: imgC,
    bg: "rgb(182, 5, 5)",
    oneWord: "GAMBLIIE",
    link:null,
    date: "Early 2021",
    tools:["PHP","JS"],
    descr:"Done as part of a school project using basic php, html and javascript features. We built an online casino site with 3 games (roulette, crash and a cashier game) with a live leaderboard."
},{
    titre: "Create and follow your watch/read list",
    url: "/projects/mwoa",
    img: imgA,
    bg: "#1F2937",
    oneWord: "MWOA",
    link:null,
    date: "Mid 2021",
    tools:["React Native","Firebase","NextJs","TailWind CSS"],
    descr:"Originally made as a mobile application, I made a website to complete this project and test Next.js. You can subscribe and create lists of movies, series, books and anime to see or read while following the activity of your friends (the application is not available online for the moment the colors/logo are taken from the Yuka application)."
}, {
    titre: "Spotify analysis plateform",
    url: "/projects/myspot",
    img: img12,
    link: "https://spotify.eliottgandiolle.fr",
    bg: "#1DB954",
    oneWord: "MYSPOT",
    date: "Early 2020",
    tools:["React","Material Ui","Spotify Api"],
    descr:"This is the first website I made in react. Thanks to the spotify api I can retrieve the information of the account that connects, then I display and analyze them thanks to Material UI."
},  {
    titre: "Spotify clone application",
    url: "/projects/spotifo",
    img: img2,
    bg: "#000",
    oneWord: "SPOTIFO",
    link:null,
    date: "Early 2021",
    tools:["React Native","AWS"],
    descr:"As its name suggests, it's just a spotify clone. It taught me a lot about how AWS works and about React Native. The application is not finished but the whole playlist/playback part is functional."
}, {
    titre: "League Of Legends friends tracker",
    url: "/projects/mlf",
    img: img42,
    link: "https://myleaguefriends.eliottgandiolle.fr",
    bg: "#B985FB",
    oneWord: "MLF",
    date: "Mid 2020",
    tools: ["React","Riot Games Api","Nodejs","Firebase","Material Ui"],
    descr: `This project allows an ordinary person to subscribe to certain player profiles of the game "League of Legends". Thanks to the editor api, a node js backend and a simple web interface you can gather several statistics in a very graphic way.`
}];