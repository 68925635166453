import React from 'react'
import "./AboutMe.css"
import moi from "../../assets/moi.jpg"

import react from "../../assets/Logos/react.png"
import python from "../../assets/Logos/mysql.jpg"
import vue from "../../assets/Logos/vue.JPG"
import wp from "../../assets/Logos/wordpress.png"
import js from "../../assets/Logos/js.png"
import php from "../../assets/Logos/php.jpg"
import node from "../../assets/Logos/node.png"
import firebase from "../../assets/Logos/firebase.png"

let tab = ["hg", "hd", "bg", "bd"];
let imgs = [react, python, vue, php, js, wp, firebase, node]

export const AboutMe = () => {

    // const windowSize = useWindowSize();
    // const [endedAnim,setEndedAnim] = useState(false);
    // const {
    //     state: {scroll},
    //   } = useScroll()
    
    // const visible = scroll > windowSize.height * 0.5;


    // useEffect(() => {
    //     if (visible) {
    //         const tl = gsap.timeline();
    //         tl.from(".about_imgAppear", 2, {
    //             height: -20,
    //             ease: "power4.out",
    //             onComplete: () => setEndedAnim(true),
    //             stagger: {
    //                 amount: 1.1
    //             }
    //         })
    //     }
    // }, [visible])

    return (
            <section className="about section">
                <div className="about_pictures">
                    <div className="about_bandeau">
                        {imgs.map((img, i) => { return i < 4 ? null : <img  src={img} alt={i + ""} key={i} className={tab[i % 4] + " about_imgAppear"} />})}
                    </div>
                    <img className="about_pdp" src={moi} alt="eliott gandiolle" />
                    <div className="about_bandeau">
                        {imgs.map((img, i) => { return i > 3 ? null : <img src={img} alt={i + ""} key={i} className={tab[i % 4] + " about_imgAppear"} />})}
                    </div>
                </div>
                <div className="about_description">
                    <p>
                    Hello ! I'm Eliott Gandiolle. I'm 20 years old and I'm a French computer science student who went freelance <s> because I'm an expert</s> but still has a lot to learn 📚.
                    </p>
                    <p>
                    I've been coding for several years now and I'm particularly passionate about React ⚛️ and its native version. 
                        Through all my projects I've <s> not made any mistakes </s> also been very interested in design (UI/UX) but also in other fields like scrapping or data sciences 📊.
                        Even if it's less frequent, I also sometimes code small games on Unreal Engine or Unity 🎮. However this page will remain focused on my mobile 📱 and web 🖥️ projects.
                    </p>
                    <p>
                    I have already worked with several partners such as Cybernano (📱/🖥️), BoatOn (🖥️), the University of Evry (🖥️) and Kanzo (📱).                    </p>
                </div>
            </section>
    )
}
