import React, { useEffect, useRef } from "react";

import "./SmoothScroll.css";
import useWindowSize from "./hooks/useWindowSize";

import { useScroll } from "../Context";

const SmoothScroll = ({ children }) => {
  // 1.
  const windowSize = useWindowSize();

  const { dispatch } = useScroll();

  //2.
  const scrollingContainerRef = useRef();

  // 3.
  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  // 4.
  useEffect(() => {
    setBodyHeight();
  }, [windowSize.height]);

  const setBodyHeight = () => {
    document.body.style.height = `${scrollingContainerRef.current.getBoundingClientRect().height
      }px`;
  };

  // 5.
  useEffect(() => {
    requestAnimationFrame(() => smoothScrollingHandler());
  }, []);

  const smoothScrollingHandler = () => {
    data.current = window.scrollY;
    if (windowSize.width < 900) {
      data.previous = data.current;
    } else {
      data.previous += (data.current - data.previous) * data.ease;
    }
    data.rounded = Math.round(data.previous * 100) / 100;

    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.style.transform = `translateY(-${data.previous}px)`;
    }
    dispatch({ type: 'change', value: data.previous })
    // Recursive call
    requestAnimationFrame(() => smoothScrollingHandler());
  };

  return (
    <div className="parent">
      <div id="snap" ref={scrollingContainerRef}> {children} </div>
    </div>
  );
};

export default SmoothScroll;