import React, { useState } from 'react'
import { MenuLink } from './MenuLink';

export const Menu = () => {

    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <div className="menu">
            <div onClick={() => setMenuOpen(!isMenuOpen)} className={"menu_home " + (isMenuOpen ? "menu_homeOpen" : "")}>
                <span> </span>
                {!isMenuOpen && <span> </span>}
                <span> </span>
            </div>
            <div className="menu_links" style={{ height: isMenuOpen ? 200 : 0 }}>
                <div style={{ margin: "10px 0" }}> </div>
                    <MenuLink index={0} mot="Home" size={45}/>
                <div style={{ margin: "10px 0" }}> </div>
                    <MenuLink index={1} mot="About" size={45} />
                <div style={{ margin: "10px 0" }}> </div>
                    <MenuLink index={2} mot="Portfolio" size={62} />
                <div style={{ margin: "10px 0" }}> </div>
                    <MenuLink index={3} mot="Contact" size={58}/>
                <div style={{ margin: "10px 0" }}> </div>
            </div>
        </div>
    )
}
