import React, { useEffect } from 'react'
import "./HeaderSection.css";
import gsap from "gsap";
import { useScroll } from '../../Context';

const tl = gsap.timeline();
const homeAnimation = (finished) => {
    tl.from(".home_titles > *", 1.8, {
        y: 200,
        ease: "power4.out",
        delay: 1,
        skewY: 10,
        opacity: 0,
        stagger: {
            amount: 0.7
        }
    }).from(".menu", 0.5, {
        scale: 0,
        ease: "power4.inout",
    }, "-=0.4").from(".scroll_menu", 1, {
        ease: "power4.out",
        y: 150,
        delay: 0.3,
        onComplete: () => finished()
    })
}

export const HeaderSection = ({ onFinished }) => {

    useEffect(() => homeAnimation(onFinished), []);
    const {
        state: {scroll},
      } = useScroll()

    return (
            <section className="home section" id="home">
                <div className="home_fadeOut"> </div>
                <div className="home_top">
                    <div className="home_titles">
                        <h1>
                            ELIOTT GANDIOLLE
                        </h1>
                    </div>
                    <div className="home_titles">
                        <h2> Mobile developer </h2>
                    </div>
                    <div className="home_titles">
                        <h2> Design enthusiast</h2>
                    </div>
                </div>
                <div className="scroll_menu" style={{opacity: scroll < 300 ? 1 - (scroll / 300) : 0}}>
                    <div className="scroll_down">
                        <div className="scroll_text">Scroll</div>
                        <div className="scroll_bar">
                            <div className="scroll_line"></div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
