import React from 'react'
import useWindowSize from '../hooks/useWindowSize';

export const MenuLink = ({ mot,size,index }) => {

    let arr = [];
    for (let i = 0; i < mot.length; i++) {
        arr.push(mot.charAt(i));
    }

    const windowSize = useWindowSize();

    return (

        <div onClick={() => window.scroll(0,windowSize.height * (index === 3 ? 5 : index))}  className="animationButton" style={{ width: size }}>
            <div className="animationFront">
                {arr.map(e => (<span key={e + Math.random()}> {e} </span>))}
            </div>
            <div className="animationBack">
                {arr.map(e => (<span key={e + Math.random()}> {e} </span>))}
            </div>
        </div>
    )
}
