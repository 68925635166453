import React, { useEffect, useRef } from 'react'
import "./SlideProject.css"
import learnMore from "../../assets/learnmore.png"
import { Link } from "react-router-dom"
import useWindowSize from '../hooks/useWindowSize'
import gsap from "gsap";
import { useScroll } from '../../Context';

export const SlideProject = ({ elem, indice }) => {

    const element = useRef(null);
    const { url, titre, img, bg, oneWord, date } = elem;
    const size = useWindowSize();

    const {
        state: {scroll},
      } = useScroll()
    
    
    let visible = false;
    if(element.current !== null) {
        visible = 600 > element.current.getBoundingClientRect().y;
    }


    useEffect(() => {
        if (visible) {
            const tl = gsap.timeline();
            tl.from(`.slide_overlay${indice} canvas`, 1.2, {
                scale: 0,
                height: "50%",
                ease: "power4.out"
            }).from(`.slide_imgOverlay${indice}`, 1.2, {
                width: "0%",
                height: "0%",
                ease: "power4.out"
            },"-=0.4").from(`.slide${indice} h2`, 1.5, {
                opacity: 0,
                ease: "power4.out"
            }, "-=1")
        }
    }, [visible,indice])

    return (
        <div className={"slide " + ("slide" + indice)} style={{ transform: (size.width < 700 ? " scale(0.6)" : ""), opacity: visible ? 1 : 0 }}>
            <h2 className="slide_aloneText"> {oneWord} </h2>
            <Link to={url}>

            <div ref={element}  className={"slide_overlay " + ("slide_overlay" + indice)} >
                <div className="slide_img_container"> 
                    <img src={img} alt="firstImage" className="slide_img" />
                </div>
                <div className="slide_infos">
                    <h2 className="slide_titre">
                        {titre}
                    </h2>
                    <h2 className="slide_titre">
                        {date}
                    </h2>
                </div>
                    <img src={learnMore} alt="learnMore" className="slide_learnMore" />
                    <div style={{ background: bg ,opacity: visible ? 1 : 0}} className={"slide_imgOverlay " + ("slide_imgOverlay" + indice)} > </div>
            </div>
            </Link>

        </div>
    )
}
